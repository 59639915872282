/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import { Suspense } from 'react';

import AddToCart from 'Component/AddToCart';
import { FieldType } from 'Component/Field/Field.config';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import { ProductType } from 'Component/Product/Product.config';
import ProductAvailability from 'Component/ProductAvailability';
import ProductCardVariants from 'Component/ProductCardVariants';
import ProductPrice from 'Component/ProductPrice';
import { PLP } from 'Component/SalableQtyErrorPopup/SalableQtyErrorPopup.config';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import { FieldContainer } from 'SourceComponent/Product/Product.component';
import {
    ProductCardComponent as SourceProductCardComponent,
} from 'SourceComponent/ProductCard/ProductCard.component';
import { ReactComponent as ArrowDown } from 'Style/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'Style/icons/arrow-up.svg';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { scrollToTop } from 'Util/Browser';
import { getIndexedAttributes } from 'Util/Product/Product';
import { ValidationInputTypeNumber } from 'Util/Validator/Config';

import './ProductCard.style.override';

/** @namespace Satisfly/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {
    renderSku(showEan = true) {
        const { getActiveProduct, isSimilarProduct } = this.props;
        const { sku, attributes } = getActiveProduct();

        if (isSimilarProduct) {
            return null;
        }

        return (
            <div block="ProductCard" elem="SkuWrapper">
                { showEan && <span block={ this.className } elem="Ean" itemProp="gtin13">{ __('EAN: %s', attributes?.ean?.attribute_value || '') }</span> }
                <span block={ this.className } elem="Sku" itemProp="sku">{ __('SKU: %s', sku ?? '') }</span>
            </div>
        );
    }

    renderPriceWidget() {
        const {
            getActiveProduct,
            product: {
                type_id: baseType,
            } = {},
            inStock,
        } = this.props;

        const {
            price_range: priceRange,
            type_id: typeId,
            customer_price = {},
        } = getActiveProduct();

        if (!priceRange) {
            return this.renderTextPlaceholder();
        }

        if (baseType === ProductType.CONFIGURABLE && !inStock) {
            return null;
        }

        // If product is not a variant.
        const notConfigured = baseType !== ProductType.CONFIGURABLE || typeId === baseType;

        const { productPrice, isSimilarProduct } = this.props;
        const product = getActiveProduct();

        const {
            type_id: type,
            price_tiers: priceTiers,
        } = product;

        if (!productPrice) {
            return null;
        }

        return (
            <div
              block={ this.className }
              elem="PriceWrapper"
            >
                <ProductPrice
                  price={ productPrice }
                  priceType={ type }
                  tierPrices={ priceTiers }
                  isPreview={ notConfigured }
                  mix={ { block: this.className, elem: 'Price' } }
                  isWidget
                  isSimilarProduct={ isSimilarProduct }
                  label={ typeId === ProductType.GROUPED && __('As low as') }
                  customerPrice={ customer_price }
                />
            </div>
        );
    }

    renderName(header = true, dynamic = false) {
        const { product: { name }, productName } = this.props;
        const nameToRender = dynamic ? productName : name;

        if (!header) {
            return (
                <p block={ this.className } elem="Name" mods={ { isWidget: true } }>
                    <TextPlaceholder content={ nameToRender } length={ TextPlaceHolderLength.MEDIUM } />
                </p>
            );
        }

        return (
            <h1 block={ this.className } elem="Title" itemProp="name" mods={ { isWidget: true } }>
                <TextPlaceholder content={ nameToRender } length={ TextPlaceHolderLength.MEDIUM } />
            </h1>
        );
    }

    renderQuantityChanger() {
        const {
            quantity,
            minQuantity,
            maxQuantity,
            setQuantity,
            setVariantQuantity,
            inStock,
            product: { type_id, stock_item: { max_sale_qty, qty_increments = 1 } = {} },
            defaultQty,
            isVariant,
            isProductPage,
        } = this.props;

        if (type_id === ProductType.GROUPED) {
            return null;
        }

        const incQty = qty_increments || 1;
        const qty = incQty > 1 ? incQty : quantity;
        const minQty = incQty > 1 ? incQty : minQuantity;
        const customRange = incQty > 1 ? { customRange: incQty } : {};

        return (
            <Suspense fallback={ null }>
                <div block="ProductCard" elem="CounterWrapper">
                    <FieldContainer
                      type={ FieldType.NUMBER_WITH_CONTROLS }
                      attr={ {
                          id: 'item_qty',
                          name: 'item_qty',
                          defaultValue: isVariant ? defaultQty : qty,
                          max: isVariant ? max_sale_qty : maxQuantity,
                          min: isVariant ? 0 : minQty,
                          isVariant,
                          ...customRange,
                      } }
                      validationRule={ {
                          inputType: ValidationInputTypeNumber.NUMERIC,
                          isRequired: true,
                          range: {
                              min: isVariant ? 0 : minQuantity,
                              max: isVariant ? max_sale_qty : maxQuantity,
                          },
                      } }
                      isDisabled={ !inStock }
                      mix={ { block: this.className, elem: 'Qty' } }
                      events={ { onChange: isProductPage && isVariant ? setVariantQuantity : setQuantity } }
                      validateOn={ ['onChange'] }
                    />
                </div>
            </Suspense>
        );
    }

    renderAddToCartButton(layout = CategoryPageLayout.LIST, isPackageProduct) {
        const {
            addToCart,
            inStock,
            quantity,
            packageQuantity,
            getActiveProduct,
            updateSelectedValues,
            isVariant,
            isProductPage,
            product: { url },
            isDailyPromo,
        } = this.props;

        if (isVariant && isProductPage) {
            return null;
        }

        return (
            <Suspense fallback={ null }>
                <AddToCart
                  mix={ { block: this.className, elem: 'AddToCart' } }
                  addToCart={ addToCart }
                  isDisabled={ !inStock }
                  isIconEnabled
                  layout={ layout }
                  updateSelectedValues={ updateSelectedValues }
                  quantity={ isPackageProduct ? packageQuantity : quantity }
                  product={ getActiveProduct() }
                  onlyIcon
                  url={ url }
                  { ...(isPackageProduct ? { isPackageProduct: true } : {}) }
                  isDailyPromo={ isDailyPromo }
                  type={ PLP }
                />
            </Suspense>
        );
    }

    renderPackageActionWrapper() {
        const {
            product: { name, package: productPackage }, isFrequentlyBoughtItemsPackage, isFrequentlyBoughtItemsView,
        } = this.props;

        if (!productPackage?.[0] || (!isFrequentlyBoughtItemsPackage && isFrequentlyBoughtItemsView)) {
            return null;
        }

        return (
            <div block="ProductCard" elem="ActionWrapper" mods={ { loaded: !!name } }>
                <div>
                    <span block="ProductCard" elem="PriceLabel">
                        { __('Package price') }
                        { ': ' }
                        { productPackage[0].package_qty }
                        { ' ' }
                        { __('pcs.') }
                        { ' ' }
                        { __('and multiples') }
                    </span>
                    { this.renderPackagePrice() }
                </div>
                <div block="ProductCard" elem="AddToCartWrapper">
                    { this.renderPackageQuantityChanger() }
                    { this.renderAddToCartButton(CategoryPageLayout.LIST, true) }
                </div>
            </div>
        );
    }

    renderNameAndSku() {
        const {
            product: { type_id },
        } = this.props;

        return (
            <>
                <div block="ProductCard" elem="MainInfo">
                    { this.renderMainDetails() }
                </div>
                <div block="ProductCard" elem="SkuEan">
                    { this.renderSku(type_id !== ProductType.GROUPED) }
                </div>
            </>
        );
    }

    renderActions() {
        const {
            product: { name },
            isFrequentlyBoughtItemsPackage,
        } = this.props;

        if (isFrequentlyBoughtItemsPackage) {
            return (
                <div block="ProductCard" elem="PackageActionWrapper">
                        { !isSignedIn() ? (
                            <div>
                                <span block="ProductCard" elem="LogInLabel">{ __('Sign in to see lower prices') }</span>
                                <Link
                                  onClick={ (e) => e.stopPropagation() }
                                  block="Button"
                                  elem="LogIn"
                                  to="/customer/account/login"
                                >
                                    { __('Sign in') }
                                </Link>
                            </div>
                        ) : (
                            this.renderPackageActionWrapper()
                        ) }
                </div>
            );
        }

        return (
            <>
                <div block="ProductCard" elem="PackageActionWrapper">
                    { !isSignedIn() ? (
                        <div>
                            <span block="ProductCard" elem="LogInLabel">{ __('Sign in to see lower prices') }</span>
                            <Link
                              onClick={ (e) => e.stopPropagation() }
                              block="Button"
                              elem="LogIn"
                              to="/customer/account/login"
                            >
                                { __('Sign in') }
                            </Link>
                        </div>
                    ) : (
                        this.renderPackageActionWrapper()
                    ) }
                </div>
                <div block="ProductCard" elem="ActionWrapper" mods={ { loaded: !!name } }>
                    <div>
                        <span block="ProductCard" elem="PriceLabel">{ __('Unit price') }</span>
                        { this.renderPrice() }
                    </div>
                    <div block="ProductCard" elem="AddToCartWrapper">
                        { this.renderQuantityChanger() }
                        { this.renderAddToCartButton() }
                    </div>
                </div>
            </>
        );
    }

    renderBottomActions() {
        const {
            product: { items }, variantsCollapsed, toggleVariants,
        } = this.props;

        return (
            <div block="ProductCard" elem="BottomActions" mods={ { expanded: !variantsCollapsed } }>
                <button
                  block="ProductCard"
                  elem="VariantsWrapper"
                  onClick={ (e) => {
                      e.preventDefault();
                      toggleVariants();
                  } }
                >
                    <span>
                        { __('Variants') }
                        <span>{ items?.length ?? 0 }</span>
                    </span>
                    <div>
                        { variantsCollapsed ? <ArrowDown /> : <ArrowUp /> }
                    </div>
                </button>
            </div>
        );
    }

    renderGroupedCardListContent() {
        const {
            layout, product: { name, type_id }, device: { isMobile },
        } = this.props;

        return (
            <div block="ProductCard" elem="Content" mods={ { layout, type_id } }>
                { !isMobile && this.renderNameAndSku() }
                <div block="ProductCard" elem="ContentWrapper" mods={ { type_id } }>
                    <div block="ProductCard" elem="ActionWrapper" mods={ { loaded: !!name } }>
                        <div>
                            <span block="ProductCard" elem="PriceLabel">{ __('Price') }</span>
                            { this.renderPrice() }
                        </div>
                    </div>
                </div>
                { !isMobile && this.renderBottomActions() }
            </div>
        );
    }

    renderSimpleCardListContent() {
        const {
            layout,
            device: { isMobile },
            product: { attributes, salable_qty },
        } = this.props;

        const indexedAttributes = (Array.isArray(attributes) ? getIndexedAttributes(attributes || []) : attributes);
        const manufacturer = indexedAttributes?.producent?.attribute_options[indexedAttributes?.producent?.attribute_value];

        return (
            <div block="ProductCard" elem="Content" mods={ { layout } }>
                { !isMobile && this.renderNameAndSku() }
                <div block="ProductCard" elem="ContentWrapper">
                    <div block="ProductCard" elem="AttributeWrapper">
                        <ProductAvailability salable_qty={ salable_qty } attributes={ indexedAttributes } />
                        { indexedAttributes?.producent?.attribute_value && (
                            <div block="ProductCard" elem="Attribute">
                                <span>{ __('Manufacturer') }</span>
                                <span>:&nbsp;</span>
                                <span>{ manufacturer?.label }</span>
                            </div>
                        ) }
                        { indexedAttributes?.nr_ref?.attribute_value && (
                            <div block="ProductCard" elem="Attribute">
                                <span>{ __('No. Ref') }</span>
                                <span>:&nbsp;</span>
                                <span>{ indexedAttributes?.nr_ref?.attribute_value }</span>
                            </div>
                        ) }
                        { indexedAttributes?.ilosc_w_opakowaniu_zbiorczym?.attribute_value && (
                            <div block="ProductCard" elem="Attribute">
                                <span>{ __('Bulk packaging') }</span>
                                <span>:&nbsp;</span>
                                <span>{ `${indexedAttributes?.ilosc_w_opakowaniu_zbiorczym?.attribute_options?.[indexedAttributes?.ilosc_w_opakowaniu_zbiorczym?.attribute_value]?.label} ${__('pcs')}` }</span>
                            </div>
                        ) }
                    </div>
                    { !isMobile && this.renderActions() }
                </div>
                { this.renderProductCardWishlistButton() }
            </div>
        );
    }

    handleLinkClick() {
        const { registerSharedElement, isPlp, isFrequentlyBoughtItemsView = false } = this.props;

        if (!isPlp && !isFrequentlyBoughtItemsView) {
            scrollToTop();
        }

        registerSharedElement(this.imageRef);
    }

    renderCardListContent() {
        const {
            renderContent,
            product: { type_id },
            device: { isMobile },
            isProductPage,
            isDailyPromo,
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        if (isDailyPromo) {
            return this.renderCardLinkWrapper((
                <div block="ProductCard" elem="Link">
                    { !isProductPage && (
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                { this.renderPicture() }
                            </figure>
                        </div>
                    ) }
                    { this.renderName(false) }
                    { this.renderActions() }
                </div>
            ));
        }

        return this.renderCardLinkWrapper((
            <>
            <div block="ProductCard" elem="Link">
                { !isProductPage && (
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                ) }
                { type_id === ProductType.GROUPED ? (
                    this.renderGroupedCardListContent()
                ) : (
                    this.renderSimpleCardListContent()
                ) }
            </div>
            { type_id !== ProductType.GROUPED && isMobile && (
                <div block="ProductCard" elem="MobileActionsWrapper">{ this.renderActions() }</div>
            ) }
            </>
        ));
    }

    renderVariants() {
        const { product: { items } } = this.props;

        return (
            <ProductCardVariants items={ items } />
        );
    }

    renderCardContent() {
        const {
            renderContent, isWidget, product: { name },
            isSubstituteProduct,
        } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        if (isSubstituteProduct) {
            return (
                this.renderCardLinkWrapper((
                    <div block="ProductCard" elem="LinkInnerWrapper" mods={ { loaded: !!name, isSubstituteProduct: true } }>
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                { this.renderPicture() }
                            </figure>
                        </div>
                        <div block="ProductCard" elem="Content">
                            <div>
                                { this.renderName(false) }
                            </div>
                            { this.renderPrice() }
                        </div>
                        <div block="ProductCard" elem="See">
                            <button
                              block="Button"
                              mods={ { isHollow: true, small: true } }
                            >
                                { __('See') }
                            </button>
                        </div>
                    </div>
                ))
            );
        }

        if (isWidget) {
            return (
                this.renderCardLinkWrapper((
                    <div block="ProductCard" elem="LinkInnerWrapper" mods={ { loaded: !!name, isWidget: !!isWidget } }>
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                { this.renderPicture() }
                            </figure>
                        </div>
                        <div block="ProductCard" elem="Content" mods={ { isWidget: true } }>
                            <div>
                                { this.renderName(false) }
                                { this.renderSku() }
                            </div>
                            <div>{ this.renderPriceWidget() }</div>
                        </div>
                    </div>
                ))
            );
        }

        return (
            this.renderCardLinkWrapper((
                <div block="ProductCard" elem="LinkInnerWrapper" mods={ { loaded: !!name } }>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderReviews() }
                        { this.renderName(false) }
                        { this.renderPrice() }
                    </div>
                    <div block="ProductCard" elem="VisibleOnHover">
                        { this.renderVisibleOnHover() }
                    </div>
                </div>
            ))
        );
    }

    render() {
        const {
            children,
            mix,
            isLoading,
            layout,
            variantsCollapsed,
            device: { isMobile },
            product: { type_id },
            isDailyPromo,
            isPlp,
        } = this.props;

        if (layout === CategoryPageLayout.LIST) {
            return (
                <li
                  block="ProductCard"
                  mods={ { layout, isDailyPromo, isPlp } }
                  mix={ mix }
                >
                    <Loader isLoading={ isLoading } />
                    { isMobile && this.renderNameAndSku() }
                    { this.renderCardListContent() }
                    { isMobile && !isDailyPromo && type_id === ProductType.GROUPED && this.renderBottomActions() }
                    { !variantsCollapsed && this.renderVariants() }
                </li>
            );
        }

        return (
            <li
              block="ProductCard"
              mods={ { layout } }
              mix={ mix }
            >
                <Loader isLoading={ isLoading } />
                { this.renderCardContent() }
                <div block="ProductCard" elem="AdditionalContent">
                    { children }
                </div>
            </li>
        );
    }
}

export default ProductCardComponent;
